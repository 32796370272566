import React, { version } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Release from "../components/release"
import SEO from "../components/seo"

const ReleaseIndex = ({ data, location }) => {
  let version = data.allMarkdownRemark.nodes[0];
  return (
    <>
      <SEO
        title={version.frontmatter.version}
        description={version.frontmatter.description || data.site.siteMetadata?.description}
      />
      <Layout location={location}>
        <Release data={version}></Release>
      </Layout>
    </>
  )
}

export default ReleaseIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit:1) {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          version
          description
        }
      }
    }
  }
`